<template>
  <div :style="{'direction': $vs.rtl ? 'rtl' : 'ltr'}">
    <!-- MODAL ADD SERVICE -->
    <vs-popup class="popup80" title="Alterar Serviço" :active.sync="popupUpdateService">

      <div class="vx-row">
        <div class="vx-col md:w-1/3 w-full mt-0">
          <vs-input v-model="service.name" class="w-full" label="Serviço" data-vv-as="Serviço" data-vv-scope="service" v-validate.initial="'required'" name="name" />
          <span class="text-danger text-sm" v-show="errors.has('service.name')">Campo Obrigatório.</span>
        </div>

        <div class="vx-col md:w-1/3 w-full mt-0">
          <label class="vs-input--label">Categoria</label>
          <v-select class="vue_select_drop_size_150" v-model="service.category_cnh" :reduce="option => option.value" :options="cnhOptions" placeholder="Selecione" :dir="$vs.rtl ? 'rtl' : 'ltr'">
            <template #no-options="{}">
              Desculpe, nenhum resultado encontrado.
            </template>
          </v-select>
        </div>

        <div class="vx-col md:w-1/3 w-full mt-0">
          <label class="vs-input--label">Plano de contas</label>
          <v-select class="vue_select_drop_size_150" v-model="service.account_plan_id" :reduce="option => option.value" :clearable="false"
              data-vv-as="Plano de Contas" data-vv-scope="service" v-validate.initial="'required'" name="accountPlan"
              :options="accountPlans" placeholder="Selecione" :dir="$vs.rtl ? 'rtl' : 'ltr'">
            <template #no-options="{}">
              Nenhum resultado encontrado.
            </template>
          </v-select>
          <span class="text-danger text-sm" v-show="errors.has('service.accountPlan')">Campo Obrigatório.</span>
        </div>
      </div>

      <div class="vx-row">
        <div class="vx-col md:w-1/4 w-full mt-0">
          <label class="vs-input--label">Tipo de Serviço</label>
          <v-select v-model="service.type_service" :clearable="true" style="background-color: white;"
            :reduce="option => option.value" :options="typeServiceOptions"
            placeholder="Selecione" :dir="$vs.rtl ? 'rtl' : 'ltr'">
            <template #no-options="{}">
              Nenhum resultado encontrado.
            </template>
          </v-select>
        </div>
        <div class="vx-col md:w-1/4 w-full mt-0">
          <label class="vs-input--label">Tipo de Pagamento</label>
          <v-select class="vue_select_drop_size_180" @input="filterAccounts" v-model="service.type_payment_id" :reduce="option => option.value" :clearable="false"
            data-vv-as="Tipo de Pagamento" data-vv-scope="contract" v-validate.initial="'required'" name="type_payment"
            :options="typePayments" placeholder="Selecione" :dir="$vs.rtl ? 'rtl' : 'ltr'">
            <template #no-options="{}">
              Desculpe, nenhum resultado encontrado.
            </template>
          </v-select>
          <span class="text-danger text-sm" v-show="errors.has('service.type_payment_id')">Este campo é obrigatório.</span>
        </div>        

        <div class="vx-col md:w-1/4 w-full mt-0">
          <label class="vs-input--label"><strong>Valor Taxas</strong></label> <br>
          <h5 class="mt-2">
            {{ service.totalTax ? service.totalTax.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : 'R$ 0,00' }}
          </h5>
        </div>

        <div class="vx-col md:w-1/4 w-full mt-0">
          <label class="vs-input--label">Valor</label>
          <currency-input class="w-full vs-inputx vs-input--input normal hasValue"
            v-model="service.value"
            :currency="{prefix: 'R$ '}"
            :valueAsInteger="false"
            :distractionFree="false"
            :precision="2"
            :autoDecimalMode="true"
            :valueRange="{ min: 0 }"
            :allowNegative="false" 
            readonly
            />
        </div>
      </div>

      <div class="vx-row flex flex-wrap items-center justify-center">
        <div class="vx-col md:w-4/5 w-full mt-2">
          <vs-divider position="left" class="d-theme-border-grey-light m-0">
            <feather-icon icon="MenuIcon" class="mr-2" svgClasses="w-4 h-4" />Ítens do Serviço
          </vs-divider>
        </div>
        <div class="vx-col md:w-1/5 w-full mt-3 flex flex-wrap justify-center">
            <vs-button @click="addServiceItem" radius color="primary" type="border" icon-pack="feather" icon="icon-plus-circle"></vs-button>
        </div>
      </div>

      <div class="vx-row" v-for="(item, i) in service.services" :key="i">
        <div class="vx-col md:w-1/6 w-full mt-0">
          <vs-input type="number" min="1" @change="calculateTotalValues" v-model="item.quantity" class="w-full" label="Qtd." />
        </div>

        <div class="vx-col md:w-1/3 w-full mt-0">
          <label class="vs-input--label">Ítem/Taxa</label>
          <v-select @input="changeItem(i)" class="vue_select_drop_size_98" v-model="item.id" :reduce="option => option.value" :clearable="false"
              :options="serviceOptions" placeholder="Selecione" :dir="$vs.rtl ? 'rtl' : 'ltr'"
              data-vv-as="Ítem|Taxa" data-vv-scope="service" v-validate.initial="{ required: !item.id }" :name="'id'+i" >
            <template #no-options="{}">
              Nenhum resultado encontrado.
            </template>
          </v-select>
          <span class="text-danger text-sm" v-show="errors.has('service.id'+i)">Campo obrigatório</span>
        </div>

        <div class="vx-col md:w-1/6 w-full mt-0">
          <label class="vs-input--label">Valor Un.</label>
          <currency-input @change="calculateTotalValues" class="w-full vs-inputx vs-input--input normal hasValue"
            v-model="item.value"
            :currency="{prefix: 'R$ '}"
            :valueAsInteger="false"
            :distractionFree="false"
            :precision="2"
            :autoDecimalMode="true"
            :valueRange="{ min: 0 }"
            :allowNegative="false" />
        </div>

        <div class="vx-col md:w-1/6 w-full mt-0">
          <label class="vs-input--label">Total</label> <br>
          {{ item.total ? item.total.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : 'R$ 0,00'  }}
          <feather-icon @click="removeServiceItem(i)" icon="Trash2Icon" class="ml-4 cursor-pointer" svgClasses="w-4 h-4" />
        </div>
      </div>


      <vs-divider></vs-divider>

      <div class="vx-row">
        <div class="vx-col w-full">
          <div class="mt-4 flex flex-wrap items-center justify-end">
            <vs-button color="secondary" class="ml-auto" @click="popupUpdateService = false">Cancelar</vs-button>
              <vs-button class="ml-4" @click="updateService" :disabled="!validateServiceForm || disableServiceSave">Salvar</vs-button>
          </div>
        </div>
      </div>
    </vs-popup>

      <!-- AÇÃO -->
      <feather-icon icon="Edit3Icon" svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer" @click="editRecord" />
      <feather-icon icon="Trash2Icon" svgClasses="h-5 w-5 hover:text-danger cursor-pointer" @click="confirmDeleteRecord" />
    </div>
</template>

<script>
import { CurrencyInput } from 'vue-currency-input'
const vSelect = () => import(/* webpackChunkName: "vSelect" */ 'vue-select')

export default {
  name: 'CellRendererActions',
  components: {
    CurrencyInput,
    vSelect
  },

  computed: {
    validateServiceForm () {
      return !this.errors.any('service')
    },
    validateItemForm () {
      return !this.errors.any('item')
    },
    accountPlans () {
      return this.$store.getters['accountPlan/forSelectFilterReceipt']
    },
    serviceOptions () {
      const services = this.$store.state.service.services
      const servicesOptions = []
      for (const k in services) {
        const type = services[k].type === 'TAX' ? 'TAXA' : 'ÍTEM'
        servicesOptions.push({
          value: services[k].id,
          label: `${services[k].service} (${type})`,
          type: services[k].type,
          val: services[k].value
        })
      }
      return servicesOptions
    },
    typePayments () {
      return this.$store.getters['typePayment/forSelect']
    } 
  },

  data () {
    return {
      popupUpdateService: false,
      disableServiceSave: false,
      disableAddItem: false,

      service: {
        name: '',
        value: 0,
        totalTax: 0,
        category_cnh: null,
        account_plan_id: null,
        services: [],
        type_service: null,
        type_payment_id: null
      },

      item: {
        id: '',
        quantity: 1,
        value: 0,
        total: 0
      },

      cnhOptions: [
        { label: 'A', value: 'A' },
        { label: 'B', value: 'B' },
        { label: 'AB', value: 'AB' },
        { label: 'C', value: 'C' },
        { label: 'D', value: 'D' },
        { label: 'E', value: 'E' },
        { label: 'AC', value: 'AC' },
        { label: 'AD', value: 'AD' },
        { label: 'AE', value: 'AE' },
        { label: 'ACC', value: 'ACC' }
      ],

      typeServiceOptions: [
        { label: '1ª Habilitação',        value: '1ª Habilitação'      },
        { label: 'Adição de categoria',   value: 'Adição de categoria' },
        { label: 'Mudança de categoria',  value: 'Mudança de categoria'},
        { label: 'Permissão ACC',         value: 'Permissão ACC'       },
        { label: 'Aula avulsa',           value: 'Aula avulsa'         }
      ]
    }
  },
  methods: {
    editRecord () {
      this.service = JSON.parse(JSON.stringify(this.params.data))
      this.service.services.map(a => { if (a.pivot) a.quantity = a.pivot.quantity })

      //calcula totais de taxas porém não insere o valor no total do serviço que pode ser diferente
      this.service.services.map(a => {
        a.total = a.quantity * a.value
      })
      const tax = this.service.services.filter(o => o.type === 'TAX')
      this.service.totalTax = parseFloat(tax.reduce((a, v) => a + (v.value * v.quantity), 0))
      //calcula totais de taxas porém não insere o valor no total do serviço que pode ser diferente

      this.popupUpdateService = true
    },

    calculateTotalValues () {
      this.service.services.map(a => {
        a.total = a.quantity * a.value
      })
      const tax = this.service.services.filter(o => o.type === 'TAX')
      this.service.totalTax = parseFloat(tax.reduce((a, v) => a + (v.value * v.quantity), 0))
      this.service.value = parseFloat(this.service.services.reduce((a, v) => a + ((v.value * v.quantity) || 0), 0))
    },
    changeItem (i) {
      const it = (this.serviceOptions.find(o => o.value === this.service.services[i].id))
      this.service.services[i].value = it.val
      this.service.services[i].type = it.type
      this.calculateTotalValues()
    },

    async updateService () {
      try {
        this.$vs.loading()
        this.popupUpdateService = false
        await this.$store.dispatch('servicePackage/update', this.service)
        this.params.setDatasource()// assim chamo a função de fora
        this.$vs.loading.close()

        this.$vs.notify({
          time: 5000,
          title: 'SUCESSO',
          text: 'Dados Salvos.',
          color: 'success',
          iconPack: 'feather',
          icon: 'icon-check'
        })
      } catch (error) {
        this.$vs.loading.close()
        if (error.response.status === 412) {
          this.$vs.notify({
            time: 5000,
            title: 'AVISO',
            text: error.response.data.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'warning'
          })
        } else {
          this.$vs.notify({
            time: 5000,
            title: 'Erro',
            text: error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          })
        }
      }
    },
    addServiceItem () {
      this.service.services.push(JSON.parse(JSON.stringify(this.item)))
    },
    removeServiceItem (i) {
      this.service.services.splice(i, 1)
      this.calculateTotalValues()
    },
    confirmDeleteRecord () {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Confirmar Exclusão',
        text: `Tem certeza que deseja excluir o serviço \n "${this.params.data.name}"?`,
        accept: this.deleteRecord,
        acceptText: 'Excluir',
        cancelText: 'Cancelar'
      })
    },
    deleteRecord () {
      this.$store.dispatch('servicePackage/delete', this.params.data.id)
        .then(()   => {
          // this.params.setDatasource();// assim chamo a função vinda de fora
          this.params.api.refreshInfiniteCache()
          this.showDeleteSuccess()
        })
        .catch(err => { console.error(err) })
    },
    showDeleteSuccess () {
      this.$vs.notify({
        color: 'success',
        title: 'SUCESSO',
        text: 'Dados excluídos com sucesso',
        iconPack: 'feather',
        icon: 'icon-check'
      })
    }
  }
}
</script>
