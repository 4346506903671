<template>
  <div id="page-services">

    <dialog-sig style="z-index: 999999999999999;" :show="popupDeleteServiceItemsConfirm" title="ALERTA" icon="warning"
      @action="removeItem(i)"
      @cancel="popupDeleteServiceItemsConfirm = false">
        Tem certeza que deseja excluir este ítem de serviço? <br>
        Esta ação é irreversível!
    </dialog-sig>

    <!-- MODAL SERVICE ITEMS -->
    <vs-popup class="popup80" title="Ítens de Serviço" :active.sync="popupServiceItems">
      <div class="vx-row">
        <div class="vx-col md:w-1/3 w-full mt-0">
          <vs-input v-model="serviceItem.name" class="w-full" label="Ítem"
            data-vv-as="Ítem" data-vv-scope="item" v-validate.initial="'required'" name="name" />
          <span class="text-danger text-sm" v-show="errors.has('item.name')">Campo Obrigatório.</span>
        </div>

        <div class="vx-col md:w-1/6 w-full mt-0">
          <label class="vs-input--label">Tipo</label>
          <v-select class="vue_select_drop_size_150" v-model="serviceItem.type" :reduce="option => option.value" :clearable="false"
              :options="[{label: 'Ítem', value: 'ITEM'}, {label: 'Taxa', value: 'TAX'}]" placeholder="Selecione" :dir="$vs.rtl ? 'rtl' : 'ltr'">
            <template #no-options="{}">
              Nenhum resultado encontrado.
            </template>
          </v-select>
        </div>

        <div class="vx-col md:w-1/6 w-full mt-0">
          <label class="vs-input--label">Valor Un.</label>
          <currency-input class="w-full vs-inputx vs-input--input normal hasValue"
            v-model="serviceItem.value"
            :currency="{prefix: 'R$ '}"
            :valueAsInteger="false"
            :distractionFree="false"
            :precision="2"
            :autoDecimalMode="true"
            :valueRange="{ min: 0 }"
            :allowNegative="false" />
        </div>
        <div class="vx-col md:w-1/3 w-full mt-4">
          <vs-button @click="addItem" :disabled="errors.has('item.name')"
            class="shadow-drop sm:mr-4 mr-0 sm:w-auto ml-auto w-full sm:order-normal order-3 sm:mt-0 mt-4"
            icon-pack="feather"
            icon="icon-plus-circle">Novo Ítem de Serviço</vs-button>
        </div>

        <vs-divider></vs-divider>

      </div>
      <ul class="ml-4">
        <li v-for="(item, i) in services" :key="i" class="flex items-center cursor-pointer py-1">
          <div v-if="!item.edit" class="vx-row w-full" :key="`if1${i}`">
            <div class="vx-col md:w-1/3 w-full mt-2">
              <feather-icon icon="CircleIcon" :svgClasses="[{ 'text-primary fill-current': true}, 'h-5 w-5']" />
              <span class="ml-2" :class="{'text-primary': true}"> {{ item.service }} </span>
            </div>
            <div class="vx-col md:w-1/3 w-full mt-2">
              <span class="ml-2" :class="{'text-primary': true}">
                {{ item.type === 'TAX' ? '(Taxa)' : '(Ítem)' }}
              </span>
            </div>
            <div class="vx-col md:w-1/3 w-full mt-2">
              <span class="ml-2" :class="{'text-primary': true}">
                {{ item.value ? item.value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : 'R$ 0,00'  }}
                <feather-icon @click="itemEdit(i)" icon="Edit3Icon" class="ml-4" svgClasses="w-4 h-4" />
                <feather-icon @click="confirmDeleteItem(i)" icon="Trash2Icon" class="ml-4" svgClasses="w-4 h-4" />
              </span>
            </div>
          </div>

          <div v-else class="vx-row w-full">
            <div class="vx-col md:w-1/3 w-full mt-0">
              <vs-input v-model="item.service" class="w-full" label="Ítem" />
            </div>

            <div class="vx-col md:w-1/4 w-full mt-0">
              <label class="vs-input--label">Tipo</label>
              <v-select class="vue_select_drop_size_150" v-model="item.type" :reduce="option => option.value" :clearable="false"
                  :options="[{label: 'Ítem', value: 'ITEM'}, {label: 'Taxa', value: 'TAX'}]" placeholder="Selecione" :dir="$vs.rtl ? 'rtl' : 'ltr'">
                <template #no-options="{}">
                  Nenhum resultado encontrado.
                </template>
              </v-select>
            </div>

            <div class="vx-col md:w-1/4 w-full mt-0">
              <label class="vs-input--label">Valor Un.</label>
              <currency-input class="w-full vs-inputx vs-input--input normal hasValue"
                v-model="item.value"
                :currency="{prefix: 'R$ '}"
                :valueAsInteger="false"
                :distractionFree="false"
                :precision="2"
                :autoDecimalMode="true"
                :valueRange="{ min: 0 }"
                :allowNegative="false" />
            </div>
            <div class="vx-col md:w-1/6 w-full mt-8">
              <feather-icon @click="updateItem(i)" icon="CheckCircleIcon" :class="{'text-primary': true}" class="cursor-pointer ml-4" svgClasses="w-6 h-6" />
            </div>
          </div>
        </li>
      </ul>
      <vs-divider></vs-divider>
      <div class="vx-row">
        <div class="vx-col w-full">
          <div class="mt-4 flex flex-wrap items-center justify-end">
            <vs-button color="secondary" class="ml-auto" @click="popupServiceItems = false">Cancelar</vs-button>
          </div>
        </div>
      </div>

    </vs-popup>

    <!-- MODAL ADD SERVICE -->
    <vs-popup class="popup80" title="Cadastro de Serviço" :active.sync="popupAddService">

      <div class="vx-row">
        <div class="vx-col md:w-1/3 w-full mt-0">
          <vs-input v-model="service.name" class="w-full" label="Serviço" data-vv-as="Serviço" data-vv-scope="service" v-validate.initial="'required'" name="name" />
          <span class="text-danger text-sm" v-show="errors.has('service.name')">Campo Obrigatório.</span>
        </div>

        <div class="vx-col md:w-1/3 w-full mt-0">
          <label class="vs-input--label">Categoria</label>
          <v-select class="vue_select_drop_size_150" v-model="service.category_cnh" :reduce="option => option.value" :options="cnhOptions" placeholder="Selecione" :dir="$vs.rtl ? 'rtl' : 'ltr'">
            <template #no-options="{}">
              Desculpe, nenhum resultado encontrado.
            </template>
          </v-select>
        </div>

        <div class="vx-col md:w-1/3 w-full mt-0">
          <label class="vs-input--label">Plano de contas</label>
          <v-select class="vue_select_drop_size_150" v-model="service.account_plan_id" :reduce="option => option.value" :clearable="false"
              data-vv-as="Plano de Contas" data-vv-scope="service" v-validate.initial="'required'" name="accountPlan"
              :options="accountPlans" placeholder="Selecione" :dir="$vs.rtl ? 'rtl' : 'ltr'">
            <template #no-options="{}">
              Nenhum resultado encontrado.
            </template>
          </v-select>
          <span class="text-danger text-sm" v-show="errors.has('service.accountPlan')">Campo Obrigatório.</span>
        </div>
      </div>

      <div class="vx-row">
        <div class="vx-col md:w-1/4 w-full mt-0">
          <label class="vs-input--label">Tipo de Serviço</label>
          <v-select v-model="service.type_service" :clearable="true" style="background-color: white;"
            :reduce="option => option.value" :options="typeServiceOptions"
            placeholder="Selecione" :dir="$vs.rtl ? 'rtl' : 'ltr'">
            <template #no-options="{}">
              Nenhum resultado encontrado.
            </template>
          </v-select>
        </div>
        <div class="vx-col md:w-1/4 w-full mt-0">
          <label class="vs-input--label">Tipo de Pagamento</label>
          <v-select class="vue_select_drop_size_180" @input="filterAccounts" v-model="service.type_payment_id" :reduce="option => option.value" :clearable="false"
            data-vv-as="Tipo de Pagamento" data-vv-scope="contract" v-validate.initial="'required'" name="type_payment"
            :options="typePayments" placeholder="Selecione" :dir="$vs.rtl ? 'rtl' : 'ltr'">
            <template #no-options="{}">
              Desculpe, nenhum resultado encontrado.
            </template>
          </v-select>
          <span class="text-danger text-sm" v-show="errors.has('service.type_payment_id')">Este campo é obrigatório.</span>
        </div>
        <div class="vx-col md:w-1/4 w-full mt-0">
          <label class="vs-input--label"><strong>Valor Taxas</strong></label> <br>
          <h5 class="mt-2">
            {{ service.totalTax ? service.totalTax.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : 'R$ 0,00' }}
          </h5>
        </div>
        <div class="vx-col md:w-1/4 w-full mt-0">
          <label class="vs-input--label"><strong>Valor Total</strong></label>
          <currency-input class="w-full vs-inputx vs-input--input normal hasValue"
            v-model="service.value"
            :currency="{prefix: 'R$ '}"
            :valueAsInteger="false"
            :distractionFree="false"
            :precision="2"
            :autoDecimalMode="true"
            :valueRange="{ min: 0 }"
            :allowNegative="false" 
            readonly
            />
        </div>
      </div>

      <div class="vx-row flex flex-wrap items-center justify-center">
        <div class="vx-col md:w-4/5 w-full mt-2">
          <vs-divider position="left" class="d-theme-border-grey-light m-0">
          <feather-icon icon="MenuIcon" class="mr-2" svgClasses="w-4 h-4" />Ítens do Serviço
          </vs-divider>
        </div>
        <div class="vx-col md:w-1/5 w-full mt-3 flex flex-wrap justify-center">
            <vs-button @click="addServiceItem" radius color="primary" type="border" icon-pack="feather" icon="icon-plus-circle"></vs-button>
        </div>
      </div>

      <div class="vx-row" v-for="(item, i) in service.items" :key="i">
        <div class="vx-col md:w-1/6 w-full mt-0">
          <vs-input type="number" min="1" @change="calculateTotalValues" v-model="item.quantity" class="w-full" label="Qtd." />
        </div>

        <div class="vx-col md:w-1/3 w-full mt-0">
          <label class="vs-input--label">Ítem/Taxa</label>
          <v-select @input="changeItem(i)" class="vue_select_drop_size_98" v-model="item.id" :reduce="option => option.value" :clearable="false"
              :options="serviceOptions" placeholder="Selecione" :dir="$vs.rtl ? 'rtl' : 'ltr'"
              data-vv-as="Ítem|Taxa" data-vv-scope="service" v-validate.initial="{ required: !item.id }" :name="'id'+i">
            <template #no-options="{}">
              Nenhum resultado encontrado.
            </template>
          </v-select>
          <span class="text-danger text-sm" v-show="errors.has('service.id'+i)">Campo obrigatório</span>
        </div>

        <div class="vx-col md:w-1/6 w-full mt-0">
          <label class="vs-input--label">Valor Un.</label>
          <currency-input @change="calculateTotalValues" class="w-full vs-inputx vs-input--input normal hasValue"
            v-model="item.value"
            :currency="{prefix: 'R$ '}"
            :valueAsInteger="false"
            :distractionFree="false"
            :precision="2"
            :autoDecimalMode="true"
            :valueRange="{ min: 0 }"
            :allowNegative="false" />
        </div>

        <div class="vx-col md:w-1/6 w-full mt-0">
          <label class="vs-input--label">Total</label> <br>
          {{ item.total ? item.total.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : 'R$ 0,00'  }}
          <feather-icon @click="removeServiceItem(i)" icon="Trash2Icon" class="ml-4 cursor-pointer" svgClasses="w-4 h-4" />
        </div>
      </div>

      <vs-divider></vs-divider>

      <div class="vx-row">
        <div class="vx-col w-full">
          <div class="mt-4 flex flex-wrap items-center justify-end">
            <vs-button color="secondary" class="ml-auto" @click="popupAddService = false">Cancelar</vs-button>
              <vs-button class="ml-4" @click="addService" :disabled="!validateServiceForm || disableServiceSave">Salvar</vs-button>
          </div>
        </div>
      </div>
    </vs-popup>

    <div class="vx-card p-6">
      <div class="flex flex-wrap items-center">

        <!-- ITEMS PER PAGE -->
        <div class="flex-grow">
          <vs-dropdown vs-trigger-click class="cursor-pointer">
            <div class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
              <span class="mr-2">{{ currentPage * paginationPageSize - (paginationPageSize - 1) }} - {{ allData.total - currentPage * paginationPageSize > 0 ? currentPage * paginationPageSize : allData.total }} de {{ allData.total }}</span>
              <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
            </div>
            <!-- <vs-button class="btn-drop" type="line" color="primary" icon-pack="feather" icon="icon-chevron-down"></vs-button> -->
            <vs-dropdown-menu>
              <vs-dropdown-item @click="paginationSetPageSize(10)">
                <span>10</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="paginationSetPageSize(20)">
                <span>20</span>
              </vs-dropdown-item>
               <vs-dropdown-item @click="paginationSetPageSize(30)">
                <span>30</span>
              </vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown>
        </div>

        <!-- NOVO SERVIÇO -->
        <vs-button @click="addServiceHandle"
          class="shadow-drop sm:mr-4 mr-0 sm:w-auto w-full sm:order-normal order-3 sm:mt-0 mt-4"
          icon-pack="feather"
          icon="icon-plus-circle">Novo Serviço</vs-button>

        <vs-button @click="serviceItemsHandle" type="border"
          class="shadow-drop sm:mr-4 mr-0 sm:w-auto w-full sm:order-normal order-3 sm:mt-0 mt-4"
          icon-pack="feather"
          icon="icon-menu">Ítens de Serviço</vs-button>

      </div>

      <!-- AgGrid Table -->
      <ag-grid-vue
        ref="agGridTable"
        :components="components"
        :gridOptions="gridOptions"
        @grid-ready="onGridReady"
        class="ag-theme-material w-100 my-4 ag-grid-table"
        :columnDefs="columnDefs"
        :defaultColDef="defaultColDef"
        :overlayLoadingTemplate="overlayLoadingTemplate"
        :overlayNoRowsTemplate="noRowsTemplate"
        :localeText="localeText"
        rowSelection="multiple"
        colResizeDefault="shift"
        :animateRows="true"
        :pagination="true"
        :paginationPageSize="paginationPageSize"
        :cacheBlockSize="40"
        :suppressPaginationPanel="true"
        :enableRtl="$vs.rtl">
      </ag-grid-vue>

      <vs-pagination :total="totalPages" :max="10" v-model="currentPage" />

    </div>

  </div>
</template>

<script>
import Vue from 'vue'
import { CurrencyInput } from 'vue-currency-input'
const vSelect = () => import(/* webpackChunkName: "vSelect" */ 'vue-select')
const dialogSig = () => import('@/components/dialog/dialogSig.vue')
//AgGrid
import { AgGridVue } from 'ag-grid-vue'
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'
import { localePt_Br } from '@/assets/agGridTranslate/locale.pt_Br'
// Cell Renderer
import CellRendererActions from './cell-renderer/CellRendererActions.vue'
// Store Module
import moduleServicePackage from '@/store/service-package/moduleServicePackage.js'
import moduleServices from '@/store/services/moduleServices.js'
import moduleAccountPlan from '@/store/account-plan/moduleAccountPlan.js'
import moduleTypePayment from '@/store/type-payment/moduleTypePayment.js'

import PaginationMixin from '@/mixins/pagination'

export default {
  mixins: [PaginationMixin],
  components: {
    AgGridVue,
    CellRendererActions,
    CurrencyInput,
    vSelect,
    dialogSig
  },
  data () {
    return {
      services: [],
      popupAddService: false,
      disableServiceSave: false,
      disableAddServiceItem: false,

      //ITEMS
      popupDeleteServiceItemsConfirm: false,
      popupServiceItems: false,
      newServiceItem: false,
      i: null,
      serviceItem: {
        name: '',
        type: 'ITEM',
        value: 0
      },

      // O SERVIÇO (QUE CONTÉM ÍTENS)
      service: {
        name: '',
        value: 0,
        totalTax: 0,
        account_plan_id: null,
        category_cnh: null,
        items: [],
        type_service: null,
        type_payment_id: null
      },

      // ITEM UTILIZADO NA INSERSÃO DE ITENS EM SERVIÇO (PACOTE)
      item: {
        id: null,
        quantity: 1,
        value: 0,
        total: 0
      },

      cnhOptions: [
        { label: 'A', value: 'A' },
        { label: 'B', value: 'B' },
        { label: 'AB', value: 'AB' },
        { label: 'C', value: 'C' },
        { label: 'D', value: 'D' },
        { label: 'E', value: 'E' },
        { label: 'AC', value: 'AC' },
        { label: 'AD', value: 'AD' },
        { label: 'AE', value: 'AE' },
        { label: 'ACC', value: 'ACC' }
      ],

      typeServiceOptions: [
        { label: '1ª Habilitação',        value: '1ª Habilitação'      },
        { label: 'Adição de categoria',   value: 'Adição de categoria' },
        { label: 'Mudança de categoria',  value: 'Mudança de categoria'},
        { label: 'Permissão ACC',         value: 'Permissão ACC'       },
        { label: 'Aula avulsa',           value: 'Aula avulsa'         }
      ],

      // AgGrid
      localeText: null,
      gridApi: null,
      dataSource: {},
      gridOptions: {
        rowModelType: 'infinite'
      },
      overlayLoadingTemplate: 'Consultando dados...',
      noRowsTemplate: 'Nenhum dado foi encontrado',
      defaultColDef: {
        sortable: true,
        resizable: true,
        suppressMenu: true
      },
      columnDefs: [
        {
          headerName: 'Serviço',
          field: 'name',
          valueFormatter: params => { return params.value ? `${params.value} ${params.data.category_cnh || ''}` : '' },
          width: 350,
          floatingFilter:true,
          filter: true,
          filterParams: {
            suppressAndOrCondition: true,
            filterOptions: ['startsWith'],
            defaultOption: 'startsWith',
            debounceMs: 800
          }
        },
        {
          headerName: 'Valor',
          field: 'value',
          valueFormatter: params => { return params.value ? params.value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : 'R$ 0,00' },
          width: 150
        },
        {
          headerName: 'Ações',
          field: 'transactions',
          width: 95,
          sortable: false,
          cellRendererFramework: 'CellRendererActions',
          cellRendererParams : {
            setDatasource : this.setDatasource.bind(this) // usado para passar parametros para dentro o componente renderizado no ag grid
          }
        }
      ],
      components: {
        CellRendererActions
      },

      payload: {
        offset: 0,
        limit: 40,
        searchQuery: null,
        params: null,
        consultApi: true
      }

    }
  },

  computed: {
    validateServiceForm () {
      return !this.errors.any('service')
    },
    validateItemForm () {
      return !this.errors.any('item')
    },
    accountPlans () {
      return this.$store.getters['accountPlan/forSelectFilterReceipt']
    },
    serviceOptions () {
      return this.$store.getters['service/getForSelect']
    },
    allData () {
      return this.$store.state.servicePackage && this.$store.state.servicePackage.servicePackages
    },
    typePayments () {
      return this.$store.getters['typePayment/forSelect']
    }    
  },
  methods: {
    setDatasource () { //usado para passar parametros para dentro o componente renderizado no ag grid
      this.payload.consultApi = true //  DEIXEI TRUE PQ ESTAVA DANDO DIFERENÇA NA QUANTIDADE DEVIDO AO FATO DE O VALOR VIR DE UM PIVOT (CUIDADO SE ALTERAR)
      this.gridApi.setDatasource(this.dataSource) // refresh do grid
    },
    itemEdit (i) {
      const service = this.services[i]
      service.edit = true
      Vue.set(this.services, i, service)

      this.$store.commit('service/MAKE_EDITABLE', i)
    },
    async updateItem (i) {
      try {
        const service = this.services[i]
        service.edit = false
        Vue.set(this.services, i, service)
        this.$store.commit('service/UPDATE', service)
        await this.$store.dispatch('service/update', i)
        this.$store.commit('service/MAKE_UNEDITABLE', i)
        this.$vs.notify({
          time: 5000,
          title: 'SUCESSO',
          text: 'Dados Alterados.',
          color: 'success',
          iconPack: 'feather',
          icon: 'icon-check'
        })
      } catch (error) {
        if (error.response.status === 412) {
          this.$vs.notify({
            time: 5000,
            title: 'AVISO',
            text: error.response.data.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'warning'
          })
        } else {
          this.$vs.notify({
            time: 5000,
            title: 'Erro',
            text: error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          })
        }
      }
    },

    //ÍTEM DE SERVIÇO, NÃO DO SERVIÇO
    async addItem () {
      try {
        const resp = await this.$store.dispatch('service/store', this.serviceItem)
        this.services.push(resp.data)
        this.$vs.notify({
          time: 5000,
          title: 'SUCESSO',
          text: 'Dados Salvos.',
          color: 'success',
          iconPack: 'feather',
          icon: 'icon-check'
        })
      } catch (error) {
        if (error.response.status === 412) {
          this.$vs.notify({
            time: 5000,
            title: 'AVISO',
            text: error.response.data.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'warning'
          })
        } else {
          this.$vs.notify({
            time: 5000,
            title: 'Erro',
            text: error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          })
        }
      }
    },

    //REMOVE ÍTEM DE SERVIÇO, NÃO DO SERVIÇO
    confirmDeleteItem (i) {
      this.i = i
      this.popupDeleteServiceItemsConfirm = true
    },

    async removeItem (i) {
      try {
        await this.$store.dispatch('service/delete', i)
        this.services.splice(i, 1)

        this.popupDeleteServiceItemsConfirm = false
        this.$vs.notify({
          time: 5000,
          title: 'SUCESSO',
          text: 'Dados Deletados.',
          color: 'success',
          iconPack: 'feather',
          icon: 'icon-check'
        })
      } catch (error) {
        if (error.response.status === 412) {
          this.$vs.notify({
            time: 5000,
            title: 'AVISO',
            text: error.response.data.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'warning'
          })
        } else {
          this.$vs.notify({
            time: 5000,
            title: 'Erro',
            text: error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          })
        }
      }
    },
    serviceItemsHandle () {
      this.serviceItem = {
        name: '',
        type: 'ITEM',
        value: 0
      }

      this.popupServiceItems = true
    },
    addServiceHandle () {
      this.service.name = ''
      this.service.value = 0
      this.service.totalTax = 0
      this.service.account_plan_id = null
      this.service.items = []
      this.popupAddService = true
    },
    async addService () {
      try {
        this.$vs.loading()
        await this.$store.dispatch('servicePackage/store', this.service)
        await this.gridApi.setDatasource(this.dataSource) // refresh do grid
        this.popupAddService = false

        this.$vs.loading.close()

        this.$vs.notify({
          time: 5000,
          title: 'SUCESSO',
          text: 'Dados Salvos.',
          color: 'success',
          iconPack: 'feather',
          icon: 'icon-check'
        })
      } catch (error) {
        this.$vs.loading.close()
        if (error.response.status === 412) {
          this.$vs.notify({
            time: 5000,
            title: 'AVISO',
            text: error.response.data.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'warning'
          })
        } else {
          this.$vs.notify({
            time: 5000,
            title: 'Erro',
            text: error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          })
        }
      }
    },
    addServiceItem () {
      this.service.items.push(JSON.parse(JSON.stringify(this.item)))
    },
    removeServiceItem (i) {
      this.service.items.splice(i, 1)
      this.calculateTotalValues()
    },
    calculateTotalValues () {
      this.service.items.map(a => {
        a.total = a.quantity * a.value
      })
      this.service.value = parseFloat(this.service.items.reduce((a, v) => a + ((v.value * v.quantity) || 0), 0))

      const tax = this.service.items.filter(o => o.type === 'TAX')
      this.service.totalTax = parseFloat(tax.reduce((a, v) => a + (v.value * v.quantity), 0))

    },
    changeItem (i) {
      const it = (this.serviceOptions.find(o => o.value === this.service.items[i].id))
      this.service.items[i].value = it.val
      this.service.items[i].type = it.type
      this.calculateTotalValues()
    },

    onGridReady () {
      const self = this
      this.dataSource = {
        rowCount: null,
        async getRows (params) {
          self.payload.offset = params.startRow
          self.payload.params = params
          if (self.payload.consultApi) await self.getAllData()
          self.payload.consultApi = true //esta linha faz com que a próxima chamada sempre seja feita para a API

          let lastRow = -1
          if (self.allData.total <= params.endRow) {
            lastRow = self.allData.total
          }
          params.successCallback(self.allData.data, lastRow)
        }
      }
      this.gridApi.setDatasource(this.dataSource)
    },

    async getAllData () {
      this.gridApi.showLoadingOverlay()
      const data =  await this.$store.dispatch('servicePackage/fetchFilter', this.payload)
      this.gridApi.hideOverlay()
      if (!data.data.total) {
        this.gridApi.showNoRowsOverlay()
      }
      return data
    }
  },
  mounted () {
    this.gridApi = this.gridOptions.api
    this.localeText = localePt_Br
    /* =================================================================
      NOTE:
      Header is not aligned properly in RTL version of agGrid table.
      However, we given fix to this issue. If you want more robust solution please contact them at gitHub
    ================================================================= */
    if (this.$vs.rtl) {
      const header = this.$refs.agGridTable.$el.querySelector('.ag-header-container')
      header.style.left = `-${  String(Number(header.style.transform.slice(11, -3)) + 9)  }px`
    }
  },
  async created () {
    this.setGrid('allData')
    if (!moduleServices.isRegistered) {
      // this.$store.registerModule('services', moduleServices)
      moduleServices.isRegistered = true
    }
    this.$store.dispatch('service/fetchServices')
      .then(() => {
        this.services = JSON.parse(JSON.stringify(this.$store.state.service.services))
      })
      .catch((err) => {
        console.error(err)
      })

    if (!moduleServicePackage.isRegistered) {
      // this.$store.registerModule('servicePackage', moduleServicePackage)
      moduleServicePackage.isRegistered = true
    }

    if (!moduleAccountPlan.isRegistered) {
      // this.$store.registerModule('accountPlan', moduleAccountPlan)
      moduleAccountPlan.isRegistered = true
    }
    this.$store.dispatch('accountPlan/fetchAll')

    if (!moduleTypePayment.isRegistered) {
      this.$store.registerModule('typePayment', moduleTypePayment)
      moduleTypePayment.isRegistered = true
    }
    this.$store.dispatch('typePayment/fetchAll').then(() => { }).catch(err => { console.error(err) })
  }
}
</script>

<style lang="scss">
#page-services {
  .user-list-filters {
    .vs__actions {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-58%);
    }
  }
}
.popup80 .vs-popup {
  width: 80% !important;
}
.vue_select_drop_size_150 .vs__dropdown-menu {
  max-height: 150px;
}
.vue_select_drop_size_98 .vs__dropdown-menu {
  max-height: 98px;
}
</style>
